<template>
  <moe-page title="新建拼团活动">
    <div class="moe-scroll-body">
      <div class="moe-scroll-body_content">
        <div class="moe-scroll-body_content_scroll">
          <moe-card class="mb-20">
            <template slot="header">
              基本信息
            </template>
            <moe-form
              ref="activityForm"
              :showback="false"
              :showTool="false"
              :model="activityParams"
              :rules="rules"
              :defaultVerify="false">
              <el-form-item label="活动名称" prop="name">
                <el-input :disabled="IS_EDIT" v-model.trim="activityParams.name" placeholder="请输入活动名称" maxlength="50" clearable></el-input>
              </el-form-item>
              <el-form-item label="活动时间" prop="startTime">
                <el-date-picker
                  :disabled="IS_EDIT"
                  v-model="dateTime"
                  clearable
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="(dateTime) => ([activityParams.startTime, activityParams.endTime] = dateTime || ['', ''])" />
              </el-form-item>
              <el-form-item label="成团人数" prop="num">
                <el-input-number
                  :disabled="IS_EDIT"
                  class="w-200"
                  v-model="activityParams.num"
                  @blur="handleChange"
                  step-strictly
                  :min="2"
                  :max="10"
                  label="请输入成团人数">
                </el-input-number>
              </el-form-item>

              <el-form-item label="成团期限" prop="deadline">
                <el-input
                  :disabled="IS_EDIT"
                  class="w-200"
                  placeholder="请输入内容"
                  :value="activityParams.deadline" @input="(value) => activityParams.deadline = value.replace(/^0+|[^0-9]/g, '')"
                  maxlength="6">
                  <template slot="append">小时</template>
                </el-input>
              </el-form-item>

              <el-form-item label="团长返现">
                <div class="df aic">
                  <el-checkbox :disabled="disabledCashback" v-model="activityParams.cashback" :border="true">订单结束后团长可返现</el-checkbox>
                  <div class="ml-10">
                    <el-tooltip content="自动成团无法团长返现" placement="top">
                      <moe-icon name="reminder" size="20px"></moe-icon>
                    </el-tooltip>
                  </div>
                </div>
              </el-form-item>

              <el-form-item label="商品详情页展示待成团订单" prop="visible">
                <moe-radio-group :disabled="IS_EDIT" :radioBoxList="$moe_data.visibleList" v-model="activityParams.visible"></moe-radio-group>
              </el-form-item>

              <el-form-item label="自动成团" prop="auto">
                <moe-radio-group :disabled="IS_EDIT" :radioBoxList="$moe_data.visibleList" v-model="activityParams.auto" @change="(value) => activityParams.autoTime = ''"></moe-radio-group>
              </el-form-item>

              <el-form-item label="自动成团时间" prop="autoTime" v-if="activityParams.auto">
                <el-input
                  :disabled="IS_EDIT"
                  class="w-300"
                  placeholder="请输入内容"
                  :value="activityParams.autoTime" @input="(value) => activityParams.autoTime = value.replace(/^0+|[^0-9]/g, '')"
                  maxlength="6">
                  <template slot="prepend">开团后</template>
                  <template slot="append">小时</template>
                </el-input>
              </el-form-item>
            </moe-form>
          </moe-card>

          <moe-card>
            <template slot="header">
              商品及优惠
            </template>
            <moe-form
              ref="activityGoodsForm"
              class="default-form"
              :showBack="false"
              :showClose="true"
              :model="activityParams"
              :rules="rules"
              :defaultVerify="false">
              <div style="height: 700px;min-height: 700px;" class="df fdc">
                <moe-table
                  :key="itemKey"
                  :numberShow="false"
                  :data="activityParams.goods"
                  :mode="false"
                  :params="{ pageNum: 1 }"
                  emptyText="请添加拼团商品">
                  <!-- 操作按钮 -->
                  <template slot="tool">
                    <el-form-item prop="goods">
                      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加商品</el-button>
                    </el-form-item>
                  </template>
                  <el-table-column label="商品信息" min-width="200">
                    <template slot-scope="{ row }">
                      <div class="df aic">
                        <moe-image :src="row.coverUrl" width="80px" height="80px" />
                        <div class="ml-10 df fdc aifs">
                          <p class="fwb tal">{{ row.name }}</p>
                          <p class="font-12 color-info">商品ID: {{ row.id }}</p>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="销售价" min-width="150">
                    <template slot-scope="{ row }">
                      {{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
                    </template>
                  </el-table-column>

                  <el-table-column label="拼团价" min-width="150">
                    <template slot-scope="{ row, $index }">
                      <div class="df aic jcc mt-20">
                        <el-form-item class="w-150" :prop="`goods.${$index}.minPrice2`" :rules="rules.minPrice2">
                          {{ row.minPrice2 ? `¥ ${row.minPrice2}` : '未设置' }}{{ $moe_math.mathGreaterThan(row.maxPrice2, row.minPrice2) ? ` - ¥ ${row.maxPrice2}` : '' }}
                        </el-form-item>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="团长返现金额" min-width="150" v-if="activityParams.cashback">
                    <template slot-scope="{ row, $index }">
                      <div class="df aic jcc mt-20">
                        <el-form-item class="w-150" :prop="`goods.${$index}.minCashbackAmount`" :rules="rules.minCashbackAmount">
                          {{ row.minCashbackAmount ? `¥ ${row.minCashbackAmount}` : '未设置' }}{{ $moe_math.mathGreaterThan(row.maxCashbackAmount, row.minCashbackAmount) ? ` - ¥ ${row.maxCashbackAmount}` : '' }}
                        </el-form-item>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="库存" prop="stock" min-width="80" />

                  <el-table-column label="操作" width="300" fixed="right">
                    <div class="moe-table_btns" slot-scope="{ row, $index }">
                      <el-button v-if="goodsEditComputed(row)" icon="el-icon-document" size="small" type="success" @click="queryShopGoodsById(row, $index)">查看设置</el-button>
                        <el-button v-else icon="el-icon-setting" size="small" type="primary" @click="queryShopGoodsById(row, $index)">设置</el-button>
                      <el-button :disabled="goodsEditComputed(row)" icon="el-icon-delete" size="small" type="danger" @click="handleDelete(row, $index)">删除</el-button>
                    </div>
                  </el-table-column>
                </moe-table>
              </div>

              <template slot="tool">
                <el-button type="primary" icon="el-icon-upload2" :loading="activityLoad" @click="handleSubmit()">{{ activityLoad ? '保存中' : '保存' }}</el-button>
              </template>
            </moe-form>
          </moe-card>
        </div>
      </div>
    </div>

    <moe-dialog :show="goodsListSpecForm.showDialog" title="拼团价设置" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <moe-form
          ref="activityGoodsItemForm"
          class="default-form"
          :showback="false"
          :showTool="false"
          :model="goodsListSpecForm"
          :rules="rules">
          <div style="height: 500px;min-height: 500px;" class="df fdc">
            <moe-table
              ref="specsTable"
              :numberShow="false"
              :data="goodsListSpecForm.list"
              :mode="false"
              :params="{ pageNum: 1 }"
              emptyText="请添加拼团商品">
              <template slot="tool">
                <div class="df aic jcfe">
                  <el-input
                    class="mr-10 w-150"
                    clearable
                    :value="goodsListSpecForm.quota"
                    placeholder="活动限购"
                    @input="(value) => goodsListSpecForm.quota = $moe_formatter.formatterInteger(value)">
                  </el-input>
                  <el-input
                    :disabled="editComputed"
                    class="mr-10 w-150"
                    clearable
                    :value="goodsListSpecForm.price"
                    placeholder="拼团价(元)"
                    @input="(value) => goodsListSpecForm.price = $moe_formatter.formatterMoney(value)">
                  </el-input>
                  <el-input
                    :disabled="editComputed"
                    v-if="activityParams.cashback"
                    class="mr-10 w-150"
                    clearable
                    :value="goodsListSpecForm.cashbackAmount"
                    placeholder="团长返现(元)"
                    @input="(value) => goodsListSpecForm.cashbackAmount = $moe_formatter.formatterMoney(value)">
                  </el-input>
                  <el-button :disabled="disabledComputed" type="primary" @click="handleBatchFill()">批量填充</el-button>
                </div>
              </template>

              <el-table-column label="商品信息" min-width="200">
                <template slot-scope="{ row }">
                  <div class="df aic">
                    <moe-image :src="row.coverUrl" width="80px" height="80px" />
                    <div class="ml-10 df fdc aifs">
                      <p class="fwb tal">{{ row.goodsName }}</p>
                      <p class="font-12 color-info">商品ID: {{ row.goodsId }}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="SKU" min-width="200">
                <template slot-scope="{ row }">
                  <div class="df aic jcc">
                    <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="仓库编号" prop="identifier" min-width="150" :show-overflow-tooltip="true"  />
              <el-table-column label="库存" prop="stock" min-width="80" />
              <el-table-column label="活动限购" min-width="200">
                <template slot-scope="{ row, $index }">
                  <el-form-item class="mt-20" :prop="`list.${$index}.quota`" :rules="rules.quota">
                    <el-input
                      :value="row.quota"
                      @input="(value) => row.quota = value.replace(/^[^1-9]+|[^0-9]/g, '')"
                      placeholder="请输入活动限购"
                      maxlength="9"
                      clearable />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="原价" prop="salePrice" min-width="150">
                <template slot-scope="{ row }">
                  {{ `¥ ${row.salePrice}` }}
                </template>
              </el-table-column>
              <el-table-column label="拼团价（￥）" min-width="150">
                <template slot-scope="{ row, $index }">
                  <el-form-item class="mt-20" :prop="`list.${$index}.price`" :rules="rules.price">
                    <el-input
                      :disabled="row.id ? true : false"
                      :value="row.price"
                      @input="(value) => row.price = $moe_formatter.formatterMoney(value)"
                      placeholder="请输入拼团价"
                      maxlength="9"
                      clearable />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="团长返现（￥）" min-width="200" v-if="activityParams.cashback">
                <template slot-scope="{ row, $index }">
                  <el-form-item class="mt-20" :prop="`list.${$index}.cashbackAmount`" :rules="rules.cashbackAmount">
                    <el-input
                      :disabled="row.id ? true : false"
                      :value="row.cashbackAmount"
                      @input="(value) => row.cashbackAmount = $moe_formatter.formatterMoney(value)"
                      placeholder="请输入团长返现金额"
                      maxlength="9"
                      clearable />
                  </el-form-item>
                </template>
              </el-table-column>
            </moe-table>
          </div>
        </moe-form>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </template>
    </moe-dialog>

    <goodsDialog
      :showActivityRepel="true"
      :showDialog.sync="showDialog"
      :defaultSelectIds="activityParams.goods && activityParams.goods.length ? activityParams.goods.map(({ id }) => id) : []"
      @close="handleClose">
    </goodsDialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityManageGroupAdd',
  components: {
    goodsDialog
  },
  computed: {
    /** 新增 | 编辑 */
    IS_EDIT() {
      if (this.activityParams.id) {
        return true
      } else {
        return false
      }
    },
    /** 禁用批量填充 */
    disabledComputed() {
      const { quota, price, cashbackAmount } = this.goodsListSpecForm;
      if (quota || price || cashbackAmount) {
        return false
      }
      return true
    },
    /** 编辑拼团优惠只能修改活动库存 */
    editComputed() {
      const { list } = this.goodsListSpecForm;
      if (!list.length) {
        return false;
      }

      return list.filter(({ id }) => id).length ? true : false;
    }
  },
  data() {
    const verify = this.$moe_verify.verifyForm
    const checkSalePrice = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入拼团价`));
      } else {
        let index = rule.fullField.split('.')[1];
        let findItem = this.goodsListSpecForm.list[index];
        if (findItem && this.$moe_math.mathGreaterThan(findItem.price, findItem.salePrice)) {
          callback(new Error(`拼团价 > 原价`));
        } else if (findItem && this.$moe_math.mathLessThanOrEqualTo(findItem.price, 0)) {
          callback(new Error(`拼团价必须大于0元`));
        } else {
          this.$refs['activityGoodsItemForm'].validateField(`list.${index}.cashbackAmount`);
          callback();
        }
      }
    }
    const checkCashbackAmount = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入团长返现金额`));
      } else {
        let index = rule.fullField.split('.')[1];
        let findItem = this.goodsListSpecForm.list[index];
        if (findItem && this.$moe_math.mathGreaterThan(findItem.cashbackAmount, findItem.salePrice)) {
          callback(new Error(`团长返现金额 > 原价`));
        } else if (findItem && this.$moe_math.mathLessThanOrEqualTo(findItem.cashbackAmount, 0)) {
          callback(new Error(`团长返现金额必须大于0元`));
        } else if (findItem && findItem.price && this.$moe_math.mathGreaterThan(findItem.cashbackAmount, findItem.price)) {
          callback(new Error(`团长返现金额 > 活动价`));
        } else {
          this.$refs['activityGoodsItemForm'].validateField(`list.${index}.salePrice`);
          callback();
        }
      }
    }
    const checkminCashbackAmount = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请设置团长返现金额'))
      } else {
        let index = rule.fullField.split('.')[1];
        let findItem = this.goodsListSpecForm.list[index];
        if (findItem && this.$moe_math.mathLessThanOrEqualTo(findItem.cashbackAmount, 0)) {
          callback(new Error(`团长返现金额必须大于0元`));
        } else {
          callback();
        }
      }
    }
    const checkDeadline = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入成团期限`));
      } else {
        if (this.activityParams.deadline && this.$moe_math.mathLessThanOrEqualTo(value, this.activityParams.autoTime)) {
          callback(new Error(`成团期限必须大于自动成团时间`));
        } else {
          callback();
          try {
            this.$refs['activityForm'].validateField(`autoTime`);
          } catch {
            console.log('error');
          }
        }
      }
    }
    const checkAutoTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入自动成团时间`));
      } else {
        if (this.activityParams.deadline && this.$moe_math.mathGreaterThanOrEqualTo(value, this.activityParams.deadline)) {
          callback(new Error(`自动成团时间必须小于成团期限`));
        } else {
          callback();
          try {
            this.$refs['activityForm'].validateField(`deadline`);
          } catch {
            console.log('error');
          }
        }
      }
    }
    return {
      activityParams: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        num: 2,
        deadline: '',
        cashback: false,
        visible: false,
        auto: false,
        autoTime: '',
        goods: [],
      },
      dateTime: [],
      goodsListSpecForm: {
        showDialog: false,
        id: '',
        quota: '',
        price: '',
        cashbackAmount: '',
        list: [],
      },
      rules: {
        name: verify.isEmpty('请输入活动名称'),
        startTime: verify.isEmpty('请选择活动时间'),
        deadline: [{ required: true, validator: checkDeadline, trigger: ['blur', 'change'] }],
        visible: verify.isEmpty('请选择是否在商品详情页展示待成团订单'),
        auto: verify.isEmpty('请选择是否自动成团'),
        autoTime: [{ required: true, validator: checkAutoTime, trigger: ['blur', 'change'] }],
        goods: verify.isEmpty('请添加拼团商品'),
        quota: verify.isEmpty('请输入活动限购'),
        price: [{ required: true, validator: checkSalePrice, trigger: ['blur', 'change'] }],
        minPrice2: verify.isEmpty('请设置拼团价'),
        minCashbackAmount: [{ required: true, validator: checkminCashbackAmount, trigger: ['blur', 'change'] }],
        cashbackAmount: [{ required: true, validator: checkCashbackAmount, trigger: ['blur', 'change'] }],
      },
      showDialog: false,
      itemKey: Math.random(),
      activityLoad: false,
      disabledCashback: false,
    }
  },
  watch: {
    'activityParams.auto'(newValue) {
      if (newValue) {
        this.activityParams.cashback = false;
        this.disabledCashback = true;
      } else {
        if (this.activityParams.id) {
          this.disabledCashback = true;
        } else {
          this.disabledCashback = false;
        }
      }
    }
  },
  methods: {
    /** 编辑的拼团商品不能删除 */
    goodsEditComputed({ skuConfigs }) {
      if (!skuConfigs.length) {
        return false;
      }

      return skuConfigs.filter(({ id }) => id).length ? true : false;
    },
    handleChange() {
      if (!this.activityParams.num) {
        this.activityParams.num = 2;
      }
    },
    /** 拼团价设置关闭 */
    handleDialogClose() {
      this.goodsListSpecForm.showDialog = false;
    },
    /** 添加商品确定 */
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.activityParams.goods.find(({ id }) => item.id === id);

        if (!findItem) {
          this.activityParams.goods.push({
            ...item,
            skuConfigs: [],
            minPrice2: '',
            maxPrice2: '',
            minCashbackAmount: '',
            maxCashbackAmount: '',
          })
        }
      })

      this.showDialog = false;
      this.$refs['activityGoodsForm'].validateField('goods');
    },
    /** 拼团价设置确定 */
    handleConfirm() {
      this.$refs['activityGoodsItemForm'].validate(() => {
        this.activityParams.goods.forEach((item) => {
          if (this.goodsListSpecForm.id === item.id) {
            let skuConfigs = this.$moe_lodash.cloneDeepData(this.goodsListSpecForm.list);
            item.skuConfigs = skuConfigs;
            item.minPrice2 = skuConfigs.map(({ price }) => Number(price)).sort((a, b) => a - b)[0];
            item.maxPrice2 = skuConfigs.map(({ price }) => Number(price)).sort((a, b) => b - a)[0];
            item.minCashbackAmount = skuConfigs.map(({ cashbackAmount }) => Number(cashbackAmount)).sort((a, b) => a - b)[0];
            item.maxCashbackAmount = skuConfigs.map(({ cashbackAmount }) => Number(cashbackAmount)).sort((a, b) => b - a)[0];
          }
        })

        let findIndex = this.goodsListSpecForm.list.findIndex(({ id }) => this.goodsListSpecForm.id === id);
        this.$refs['activityGoodsItemForm'].validateField(`goods.${findIndex}.minPrice2`);
        this.$refs['activityGoodsItemForm'].validateField(`goods.${findIndex}.minCashbackAmount`);

        this.itemKey = Math.random();

        this.handleDialogClose();
      })
    },
    /** 查询商品详情 */
    queryShopGoodsById(row, index) {
      this.goodsListSpecForm.id = row.id;
      this.$moe_api.GOODS_API.queryShopGoodsById({ id: row.id }).then((data) => {
        if (data.code === 200) {
          let r = data.result;

          let goodsItem = this.$moe_lodash.cloneDeepData(r.goodsItem);
          if (this.activityParams.goods[index].skuConfigs.length) {
            this.goodsListSpecForm.list = this.$moe_lodash.cloneDeepData(this.activityParams.goods[index].skuConfigs).map((item) => {
              let findItem = goodsItem.find(({ id }) => item.goodsItemId === id);
              return {
                ...findItem,
                ...item,
                goodsId: r.id,
                goodsName: r.name,
              }
            });
          } else {
            this.goodsListSpecForm.list = goodsItem.filter(({ delFlag }) => delFlag === 0).map((item) => {
              return {
                ...item,
                goodsItemId: item.id,
                id: '',
                goodsId: r.id,
                goodsName: r.name,
                quota: '',
                price: '',
                cashbackAmount: '',
              }
            })
          }

          this.goodsListSpecForm.showDialog = true;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 批量填充 */
    handleBatchFill() {
      this.goodsListSpecForm.list.forEach((item) => {
        item.price = this.goodsListSpecForm.price || item.price;
        item.quota = this.goodsListSpecForm.quota || item.quota;
        if (this.activityParams.cashback) {
          item.cashbackAmount = this.goodsListSpecForm.cashbackAmount || item.cashbackAmount;
        } else {
          item.cashbackAmount = 0;
        }
      })

      this.$moe_msg.success(`成功改变${this.goodsListSpecForm.list.length}条数据`);

      this.goodsListSpecForm.quota = this.goodsListSpecForm.price = this.goodsListSpecForm.cashbackAmount = '';
    },
    /** 删除添加的商品 */
    handleDelete({ name }, index) {
      this.$moe_layer.confirm(`您确定要删除当前选项“${name}”吗？`, () => {
        this.activityParams.goods.splice(index, 1);

        this.goodsSearch(false);
      });
    },
    async handleSubmit() {
      let activityForm = new Promise((resolve, reject) => {
        this.$refs['activityForm'].validate(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });
      let activityGoodsForm = new Promise((resolve, reject) => {
        this.$refs['activityGoodsForm'].validate(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });

      await Promise.all([
        activityForm,
        activityGoodsForm,
      ]).then(() => {
        this.activityLoad = true;
        let params = {
          ...this.activityParams,
          goods: this.activityParams.goods.map((item) => {
            return {
              id: item.id,
              skuConfigs: item.skuConfigs.map(({ cashbackAmount, id, goodsItemId, quota, price }) => {
                return {
                  id: this.IS_EDIT ? id || '' : '',
                  goodsId: item.id,
                  cashbackAmount: this.activityParams.cashback ? cashbackAmount : 0,
                  goodsItemId,
                  quota,
                  price,
                }
              })
            }
          })
        }
        if (this.IS_EDIT) {
          this.req_updateGroupActivity(params);
        } else {
          this.req_addGroupActivity(params);
        }
      }).catch(() => {
        this.$moe_msg.warning('请完善信息 !')
      })
    },
    /** 创建拼团活动 */
    req_addGroupActivity(params) {
      this.$moe_api.GROUPACTIVITY_API.addGroupActivity(params).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新建成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.activityLoad = false;
      });
    },
    /** 修改拼团活动 */
    req_updateGroupActivity(params) {
      this.$moe_api.GROUPACTIVITY_API.updateGroupActivity(params).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.activityLoad = false;
      });
    },
    /** 获取拼团活动详情 */
    req_getGroupActivityDetail() {
      this.$moe_api.GROUPACTIVITY_API.getGroupActivityDetail({ id: this.$route.query.id }).then((data) => {
        if (data.code === 200) {
          let { id, name, startTime, endTime, num, deadline, cashback, visible, auto, autoTime, goods } = data.result;
          this.activityParams = {
            id,
            name,
            startTime,
            endTime,
            num,
            deadline,
            cashback,
            visible,
            auto,
            autoTime,
            goods: goods.map((item) => {
              return {
                ...item,
                name: '',
                coverUrl: '',
                minPrice: '',
                maxPrice: '',
                minPrice2: item.skuConfigs.map(({ price }) => Number(price)).sort((a, b) => a - b)[0],
                maxPrice2: item.skuConfigs.map(({ price }) => Number(price)).sort((a, b) => b - a)[0],
                minCashbackAmount: item.skuConfigs.map(({ cashbackAmount }) => Number(cashbackAmount)).sort((a, b) => a - b)[0],
                maxCashbackAmount: item.skuConfigs.map(({ cashbackAmount }) => Number(cashbackAmount)).sort((a, b) => b - a)[0],
              }
            })
          };
          this.disabledCashback = true;
          this.dateTime = [startTime, endTime];
          if (goods.length) {
            this.req_getQueryShopGoodsByIds(goods);
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 根据商品ID获取商品列表 */
    req_getQueryShopGoodsByIds(goods) {
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: goods.map(({ id }) => id).join(',') }).then((data) => {
        if (data.code === 200) {
          let result = data.result;
          this.activityParams.goods.forEach((item) => {
            let findItem = result.find(({ id }) => item.id === id);
            item.stock = findItem.stock || 0;
            item.name = findItem.name || '';
            item.coverUrl = findItem.coverUrl || '';
            item.minPrice = findItem.minPrice || '';
            item.maxPrice = findItem.maxPrice || '';
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.req_getGroupActivityDetail();
    }
  }
}
</script>

<style lang="scss" scoped></style>